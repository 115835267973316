<template>
  <div class="l-wrapper">
    <q-scroll-area class="l-grow q-pa-md">
      <div class="column q-col-gutter-sm">
        <q-input
          outlined
          readonly
          label="Company Name"
          v-model="data.selCustomer.name"
        ></q-input>
        <q-input
          outlined
          readonly
          label="Current Type"
          v-model="data.selCustomer.type"
        ></q-input>
        <q-select
          stack-label
          outlined
          v-model="new_type.type_after"
          :options="filteredType"
          label="New type"
        ></q-select>
        <q-input
          stack-label
          outlined
          label="Catatan"
          v-model="new_type.note"
          type="textarea"
          autogrow
        ></q-input>
      </div>
    </q-scroll-area>
    <q-btn
      unelevated
      label="save"
      color="primary"
      @click="updateCustomerType(new_type)"
      :disable="!new_type.type_after || new_type.note == ''"
    ></q-btn>
  </div>
</template>
<script>
import { ref, reactive, computed } from "vue";
import { onMounted } from "vue";
import useCustomer from "./useCustomer";
export default {
  setup() {
    const {
      data,
      getType,
      getDetail,
      route,
      updateCustomerType,
      $q,
    } = useCustomer();

    let new_type = reactive({ type_after: null, note: "" });

    let filteredType = computed(() => {
      return data.type.filter((val) => {
        return val != data.selCustomer.type;
      });
    });

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getType();
        await getDetail(route.params.id);
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    return {
      data,
      getType,
      getDetail,
      new_type,
      filteredType,
      updateCustomerType,
    };
  },
};
</script>
